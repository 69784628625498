<template>
    <div
    class="pa-5 fill-height background"
    align-content="center"
    justify="center"
    style="height: 100% !important;"
    >

    <v-container style="height: 100% !important;">
        <v-row
        class="fill-height"
        align-content="center"
        justify="center"
        >
            <v-col
            class="white--text text-h6 text-center text-bold text-loading"
            cols="12"
            >
                <div class="pb-5">
                    Loading..
                </div>
                <div class="cube">
                  <div class="side"></div>
                  <div class="side"></div>
                  <div class="side"></div>
                  <div class="side"></div>
                  <div class="side"></div>
                  <div class="side"></div>
                </div>
            </v-col>
        </v-row>
    </v-container>
    </div>

</template>

<script>
// import axios from 'axios';
// import { bus } from '@/main';

export default {

  data: () => ({
    
  }),

  mounted() {
    
    this.checkLoginFrom();
    
  },

  methods: {

    checkLoginFrom()
    {
      // console.log("HELLLLLLLLLLLLLLOOOOOOOOOOOOOO WORLD");

      // console.log(location.href.includes("id_token"));

      // setTimeout(() => {

        console.log("this is location" , location);

        if(location.href.includes("id_token") == true)
        {

          let objLoginPlus = {
            id_token: location.href.split("id_token=")[1]
          }

          let payload = {
            mode: "PLUS",
            objtoken: objLoginPlus,
          }

          this.$store.dispatch("signUserIn", payload)

        }
        else
        {
          setTimeout(() => {  
                          
              this.goToPage("/LandingPage")

          }, 3000)
          
        }

      // }, 10000)
    },
     
  },

};
</script>

<style lang="scss">

.background{
    background-image: url("~@/assets/img/wallpaper/Main Page.png") !important;
    background-size:cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.cube {
  margin: auto;
  font-size: 24px;
  height: 1em;
  width: 1em;
  position: relative;
  transform: rotatex(30deg) rotatey(45deg);
  transform-style: preserve-3d;
  animation: cube-spin 1.5s infinite ease-in-out alternate;
}

.side {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-style: preserve-3d;
}
.side::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: currentcolor;
  transform: translatez(0.5em);
  animation: cube-explode 1.5s infinite ease-in-out;
  opacity: 0.5;
}

.side:nth-child(1) {
  transform: rotatey(90deg);
}

.side:nth-child(2) {
  transform: rotatey(180deg);
}

.side:nth-child(3) {
  transform: rotatey(270deg);
}

.side:nth-child(4) {
  transform: rotatey(360deg);
}

.side:nth-child(5) {
  transform: rotatex(90deg);
}

.side:nth-child(6) {
  transform: rotatex(270deg);
}

@keyframes cube-spin {
  0% {
    transform: rotatex(30deg) rotatey(45deg);
  }
  100% {
    transform: rotatex(30deg) rotatey(405deg);
  }
}
@keyframes cube-explode {
  0% {
    transform: translatez(0.5em);
  }
  50% {
    transform: translatez(0.75em);
  }
  100% {
    transform: translatez(0.5em);
  }
}

</style>